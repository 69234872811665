<template>
  <MenuButton
    class="relative"
    :variant="cart.totalUniqueItems > 0 ? 'accent': variant"
    :title="$t('text-shopping-cart')"
    placement="bottom"
    :aria-label="$t('text-cart')"
    :on-click="handleClick"
  >
    <span class="sr-only">{{ $t('text-cart') }}</span>
    <ShoppingBagIcon class="w-6 h-6" />
    <span v-if="cart.totalUniqueItems > 0" class="bg-light py-0.5 px-1 text-xs leading-none font-bold text-primary-dark rounded-full absolute top-0 end-0 mt-0 -me-1">
      {{ cart.totalUniqueItems }}
    </span>
  </MenuButton>
</template>

<script setup>
import { useAuthStore } from '@stores/auth';
import { useCartStore } from '@stores/cart';
import ShoppingBagIcon from "@components/icons/shopping-bag-icon";
import MenuButton from "@components/ui/menu-button";

defineProps({
  variant: {
    type: String,
    default: 'outline'
  }
});

const auth = useAuthStore();
const cart = useCartStore();
const { $eventBus } = useNuxtApp();

const handleClick = () => {
  if (auth.isLoggedIn) {
    $eventBus.emit('sidebar:open', 'CART_VIEW');
  } else {
    $eventBus.emit('modal:open', 'LOGIN_VIEW');
  }
}

</script>
