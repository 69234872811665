<template>
    <div class="flex flex-row items-center space-x-2">
        <div v-tippy="$t('change-locale')" class="flex items-center">
            <GlobeIcon class="w-4 h-4" />
        </div>
        <Link
            v-for="item in enums.languages"
            :key="item.code"
            :class="[ 'uppercase no-underline hover:underline cursor-pointer' ]"
            :href="switchLocalePath(item.code)"
            active-class="font-bold"
            :title="item.name"
            @click.prevent="() => handleClick(item)"
        >
            {{ item.code }}
        </Link>
    </div>
</template>

<script setup>
import _ from 'lodash-es';
import { useEnumStore } from '@stores/enum';
import { useSelectedStore } from '@stores/selected';
import GlobeIcon from "@components/icons/outline/globe-icon";
import Link from "@components/ui/link/link";

const enums = useEnumStore();
const store = useSelectedStore();
const switchLocalePath = useSwitchLocalePath();

const handleClick = async (item) => {
    await navigatorTo(switchLocalePath(item.code));
    await visitorUpdate(item.id);
    store.setLoadResetAll();
    _.delay(async () => {
        await loadPublicMasterData();

        // await loadCategories(true);
        // await loadParameters(true);
        // await loadEshopAvailabilities(true);
        // await loadAbandonmentReasons(true);
        // await loadBanners(true);
    }, 500);
}

</script>
